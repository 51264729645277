export interface Props {
    id: string;
    type: string;
    placeholder: string;
    value: string;
    onchange: React.ChangeEventHandler<HTMLInputElement>;
}

const FormField = ({id, type, placeholder, value, onchange} : Props) => {
    return (
        <div className="form-group">
            <input type={type}
                className="form-control" 
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onchange}
            />
        </div>
    )
}

export default FormField
export interface Props {
    sqSize: number,
    totalCount: number,
    strokeWidth: number
}

const CircularProgress = ({ sqSize, totalCount, strokeWidth }: Props) => {
    const percentage = totalCount / 25000 * 100
    const radius = (sqSize - strokeWidth) / 2
    const viewBox = `0 0 ${sqSize} ${sqSize}`
    const dashArray = radius * Math.PI * 2
    const dashOffset = dashArray - dashArray * percentage / 100

    return (
        <svg
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}>
            <circle
                className="circle-background"
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`} />
            <circle
                className="circle-progress"
                cx={sqSize / 2}
                cy={sqSize / 2}
                r={radius}
                strokeWidth={`${strokeWidth}px`}
                // Start progress marker at 12 O'Clock
                transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
                style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                }} />
            <text
                className="circle-text"
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle">
                {totalCount}
            </text>
            <text
                className="circle-sub-text"
                x="50%"
                y="70%"
                dy=".3em"
                textAnchor="middle">
                Completed
            </text>
            <text
                className="circle-sub-text"
                x="50%"
                y="75%"
                dy=".3em"
                textAnchor="middle">
                Assessment
            </text>
        </svg>
    );
}

export default CircularProgress
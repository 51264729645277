import React from 'react';

export interface Props {
    text: string;
    classname: string;
    onclick: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({classname, text, onclick} : Props) => {
    return <button type="button" className={"btn " + classname} onClick={onclick}>{text}</button>
}

export default Button
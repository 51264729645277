import './CMDQ.css'
import '../../App.css'

export interface Props {
    id: string
    value: string;
    checked: string;
    classname: string;
    onchange: React.ChangeEventHandler<HTMLInputElement>;
}

const CMDQTableTDRadio = ({id, value, checked, classname, onchange} : Props) => {
	return (
    <td className={classname}>
	    <input id={id} className="form-check-input" type="radio" checked={checked === value}
            value={value}
	        onChange={onchange} />
    </td>
	);
};

export default CMDQTableTDRadio
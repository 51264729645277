import '../App.css'
import FormField from './FormField'
import Background from "../assets/BG-1.png"
import TMLogo from "../assets/TM-Logo.png"
import { useEffect, useState } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
import { Link, useHistory } from 'react-router-dom'

Amplify.configure(awsconfig)

export interface Props {
    onSignIn(): any;
}

const AdminLoginForm = ({ onSignIn }: Props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    const history = useHistory()

    useEffect(() => {
        if (username.length > 0 && password.length > 0) {
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password])

    const signIn = async () => {
        try {
            setBtnDisabled(true)
            await Auth.signIn(username, password)
                .then(result => {
                    setBtnDisabled(false)
                    // console.log(result)
                    const payload = {
                        "name": username,
                    }
                    history.push({
                        pathname: '/admin-dashboard',
                        state: { payload }
                    })
                })
                .catch(err => {
                    let error: any = err
                    setLoginError(error.message)
                });
        } catch (error) {
            setBtnDisabled(false)
            // console.log('Error signing out', error)
        }
    }

    const handleChange = (e: { target: { id: any; value: any; }; }) => {
        const { id, value } = e.target
        switch (id) {
            case "username":
                setUsername(value)
                break
            case "password":
                setPassword(value)
                break
        }
    }

    const renderError = () => {
        if (loginError.length > 0 ) {
            return <div className="alert alert-danger" role="alert">
                    {loginError}
                </div>
        } else {
            return <div></div>
        }
    }

    return <div className="container-fluid text-center height-100-vh login-bg" style={{ backgroundImage: `url(${Background})` }}>
        <div className="row justify-content-center">
            <div className="col-md-6 margin-top-15p">
                <div className="card card-shadow padding-1rem">
                    <div className="card-body">
                        <h3 className="card-title text-left"><img src={TMLogo} alt="TM Logo" width="100" /></h3>
                        <h3 className="card-title text-left">Welcome to Admin Page</h3>
                        {renderError()}
                        
                        <br />
                        <FormField id="username" type="text" placeholder="Username" value={username} onchange={handleChange} />
                        <br />
                        <FormField id="password" type="password" placeholder="Password" value={password} onchange={handleChange} />
                        <br />
                        <button
                            type="submit"
                            className="btn btn-primary width-50p padding-1rem font-weight-bold"
                            disabled={btnDisabled}
                            onClick={signIn}
                        >LOGIN</button>
                        <br />
                        <Link className="btn btn-outline-primary margin-top-1rem width-50p font-weight-bold" to="/">Normal Login</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AdminLoginForm
import './CMDQ.css'
import '../../App.css'
import React, { useState, useEffect } from 'react';
import CMDQTableTDRadio from './CMDQTableTDRadio'

export interface Props {
  sendData: any
}

const CMDQTableForm3 = ({ sendData }: Props) => {
  const [isFormCompleted, setIsFormCompleted] = useState(false)
  const [tableData, setTableData] = useState({
    PERightHandAreaA: "",
    PSRightHandAreaA: "",
    WIRightHandAreaA: "",
    PERightHandAreaB: "",
    PSRightHandAreaB: "",
    WIRightHandAreaB: "",
    PERightHandAreaC: "",
    PSRightHandAreaC: "",
    WIRightHandAreaC: "",
    PERightHandAreaD: "",
    PSRightHandAreaD: "",
    WIRightHandAreaD: "",
    PERightHandAreaE: "",
    PSRightHandAreaE: "",
    WIRightHandAreaE: "",
    PERightHandAreaF: "",
    PSRightHandAreaF: "",
    WIRightHandAreaF: ""
  })

  useEffect(() => {
    let checkIsFormCompleted = false
    let dataSendToParent = {}    

    for (const value of Object.values(tableData)) {
      if (isNaN(parseInt(value))) {
        checkIsFormCompleted = false;
        break
      }
      checkIsFormCompleted = true;
    }

    checkIsFormCompleted ? setIsFormCompleted(true) : setIsFormCompleted(false)

    if (isFormCompleted) {
      dataSendToParent = {
        isBtnDisable: true,
        tableData3: tableData
      }
      sendData(dataSendToParent)
    } else {
      dataSendToParent = {
        isBtnDisable: false,
        tableData3: {}
      }
      sendData(dataSendToParent)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, isFormCompleted])

  const handleChange = (e: { target: { id: string; value: string; }; }) => {
    const { id, value } = e.target
    let isPainExp = id.substring(0,2)
    
    if (isPainExp === "PE" && value === "1") {
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "") {
        painSevValue = "0"
      }
      if (workIntValue === "") {
        workIntValue = "0"
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else if (isPainExp === "PE" && value !== "1") {

      // checkPsPeWiValue(bodyPart, value)
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "0") {
        painSevValue = ""
      }
      if (workIntValue === "0") {
        workIntValue = ""
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else {
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
      }))
    }
  }

  const checkPsValue = (bodyPart: string) => {
    let painSevValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          painSevValue = value
          break;      
        default:
          break;
      }
    }
    return painSevValue
  }

  const checkWiValue = (bodyPart: string) => {
    let workIntValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          workIntValue = value
          break;      
        default:
          break;
      }
    }
    return workIntValue
  }

	return (
    <tbody>
      <tr>
        <th scope="row">Area A - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaA" value="1" checked={tableData.PERightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaA" value="2" checked={tableData.PERightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaA" value="3" checked={tableData.PERightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaA" value="4" checked={tableData.PERightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaA" value="5" checked={tableData.PERightHandAreaA} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaA" value="1" checked={tableData.PSRightHandAreaA} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaA" value="2" checked={tableData.PSRightHandAreaA} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaA" value="3" checked={tableData.PSRightHandAreaA} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaA" value="1" checked={tableData.WIRightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaA" value="2" checked={tableData.WIRightHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaA" value="3" checked={tableData.WIRightHandAreaA} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area B - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaB" value="1" checked={tableData.PERightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaB" value="2" checked={tableData.PERightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaB" value="3" checked={tableData.PERightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaB" value="4" checked={tableData.PERightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaB" value="5" checked={tableData.PERightHandAreaB} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaB" value="1" checked={tableData.PSRightHandAreaB} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaB" value="2" checked={tableData.PSRightHandAreaB} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaB" value="3" checked={tableData.PSRightHandAreaB} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaB" value="1" checked={tableData.WIRightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaB" value="2" checked={tableData.WIRightHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaB" value="3" checked={tableData.WIRightHandAreaB} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area C - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaC" value="1" checked={tableData.PERightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaC" value="2" checked={tableData.PERightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaC" value="3" checked={tableData.PERightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaC" value="4" checked={tableData.PERightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaC" value="5" checked={tableData.PERightHandAreaC} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaC" value="1" checked={tableData.PSRightHandAreaC} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaC" value="2" checked={tableData.PSRightHandAreaC} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaC" value="3" checked={tableData.PSRightHandAreaC} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaC" value="1" checked={tableData.WIRightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaC" value="2" checked={tableData.WIRightHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaC" value="3" checked={tableData.WIRightHandAreaC} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area D - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaD" value="1" checked={tableData.PERightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaD" value="2" checked={tableData.PERightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaD" value="3" checked={tableData.PERightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaD" value="4" checked={tableData.PERightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaD" value="5" checked={tableData.PERightHandAreaD} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaD" value="1" checked={tableData.PSRightHandAreaD} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaD" value="2" checked={tableData.PSRightHandAreaD} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaD" value="3" checked={tableData.PSRightHandAreaD} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaD" value="1" checked={tableData.WIRightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaD" value="2" checked={tableData.WIRightHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaD" value="3" checked={tableData.WIRightHandAreaD} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area E - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaE" value="1" checked={tableData.PERightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaE" value="2" checked={tableData.PERightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaE" value="3" checked={tableData.PERightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaE" value="4" checked={tableData.PERightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaE" value="5" checked={tableData.PERightHandAreaE} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaE" value="1" checked={tableData.PSRightHandAreaE} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaE" value="2" checked={tableData.PSRightHandAreaE} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaE" value="3" checked={tableData.PSRightHandAreaE} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaE" value="1" checked={tableData.WIRightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaE" value="2" checked={tableData.WIRightHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaE" value="3" checked={tableData.WIRightHandAreaE} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area F - shaded area</th>
        <CMDQTableTDRadio id="PERightHandAreaF" value="1" checked={tableData.PERightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaF" value="2" checked={tableData.PERightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaF" value="3" checked={tableData.PERightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaF" value="4" checked={tableData.PERightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PERightHandAreaF" value="5" checked={tableData.PERightHandAreaF} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSRightHandAreaF" value="1" checked={tableData.PSRightHandAreaF} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaF" value="2" checked={tableData.PSRightHandAreaF} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSRightHandAreaF" value="3" checked={tableData.PSRightHandAreaF} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIRightHandAreaF" value="1" checked={tableData.WIRightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaF" value="2" checked={tableData.WIRightHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIRightHandAreaF" value="3" checked={tableData.WIRightHandAreaF} onchange={handleChange} classname="background-orange"/>
      </tr>
    </tbody>
	);
};

export default CMDQTableForm3
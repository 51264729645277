export class AppService {
    public async helloworld(): Promise<any> {
        const response = await fetch('https://kecywz7tsj.execute-api.ap-southeast-1.amazonaws.com/Prod/hello');
        return await response.json();
    }
    
    public async getReports(): Promise<any> {
        const response = await fetch('https://kecywz7tsj.execute-api.ap-southeast-1.amazonaws.com/Prod/getbucket');
        return await response.json();
    }

    public async generateReport(dataFilter: any) {
        try {
            fetch('https://kecywz7tsj.execute-api.ap-southeast-1.amazonaws.com/Prod/export', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({dataFilter})
            }).then(
                (data) => {
                    console.log("then");
                    console.log(data);
                }
            ).finally(
                () => {
                }
            ).catch(
                (err) => {
                    console.log("err");
                    console.log(err);
                }
            )
            
        } catch (err) {
            
        }
        return true
    }

}
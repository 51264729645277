import '../App.css'
import './Admin/Admin.css'
import 'react-circular-progressbar/dist/styles.css';
// import Button from './Button'
import AdminTable from './AdminTable'
import AdminSideBar from './AdminSideBar'
import { useEffect, useState } from 'react';
import Amplify, { API, Auth, Storage } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql/';
import awsconfig from '../aws-exports'
import { listCMDQS } from '../graphql/queries'
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from './CircularProgress';

import { AppService } from '../services/app.service';

const appService = new AppService();

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const Years = [
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
]

const LOB = [
  "Business Innovation Group (BIG)",
  "Central Function",
  "Group Information Technology & Digital (GITD)",
  "Group Network Technology (GNT)",
  "Group Customer Experience (GCX)",
  "Support Business",
  "TM One",
  "TM Wholesale",
  "Unifi",
]

const Subsidiary = [
  "Acasia",
  "Fiberail",
  "Fibercomm",
  "GITN Sdn Bhd",
  "Menara Kuala Lumpur ",
  "PT. VADS Indonesia",
  "Telekom Malaysia (AU) Pty",
  "Telekom Malaysia (HK) Ltd",
  "Telekom Malaysia (UK) Ltd",
  "Telekom Malaysia (US) Inc",
  "Telekom Malaysia(S) P Ltd",
  "TM Applied Business Sdn Bhd",
  "TM Info-Media Sdn Bhd",
  "TM R&D Sdn Bhd",
  "TM Sales & Services Sdn Bhd (TSSSB)",
  "TMF Autolease Sdn Bhd",
  "Universiti Telekom Sdn Bhd",
  "VADS Berhad",
  "VADS Business Process",
  "Webe Digital Sdn Bhd"
]

const DataView = [
  25,
  50,
  75,
  100
]

Amplify.configure(awsconfig)

interface PayloadState {
  payload: {
    name: string
  }
}

const AdminDashboard = () => {
  // const [loggedIn, setLoggedIn] = useState(true) 
  const location = useLocation()
  const adminUserName = location.state as PayloadState
  const [isDataTableView, setIsDataTableView] = useState(true)
  const [isReportTableView, setIsReportTableView] = useState(false)
  const [cmdqDatas, setCmdqDatas] = useState([])
  const [allCmdqDatas, setAllCmdqDatas] = useState([])
  const [cmdqFilteredDatas, setCmdqFilteredDatas] = useState([])
  const [cmdqSelectedDatas, setCmdqSelectedDatas] = useState([])
  const [excelReportDatas, setExcelReportDatas] = useState([])
  const [excelReportData, setExcelReportData] = useState([])
  const [isGenerateBtnEnable, setIsGenerateBtnEnable] = useState(false)
  const [isShowGenReportMessage, setIsShowGenReportMessage] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [cmdqDataCount, setCmdqDataCount] = useState(0)
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false)
  const [filterDataName, setFilterDataName] = useState("")
  const [pdfDownloadStatusError, setPdfDownloadStatusError] = useState(false)
  const [pdfDownloadStatusMsg, setPdfDownloadStatusMsg] = useState("")
  const [filterData, setFilterData] = useState({
    LOB: LOB[0],
    view: 25
  })
  const [filterMonthYear, setFilterMonthYear] = useState({
    month: 'Jul',
    year: '2021'
  })
  const history = useHistory()

  useEffect(() => {
    AssessLoggedInState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchCmdqDataCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((filterDataName.length === 0 && cmdqDatas && cmdqDatas.length > 0) || cmdqFilteredDatas.length > 0) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
  }, [cmdqDatas, cmdqFilteredDatas, filterDataName])

  useEffect(() => {
    fetchCmdqDatas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.LOB])

  let adminUsrName = ""
  try {
    adminUsrName = adminUserName.payload.name
  } catch (err) {
    // console.log("Error reading user payload")
  }

  const AssessLoggedInState = async () => {
    Auth.currentAuthenticatedUser().then(() => {
    }).catch(() => {
      history.push('/admin-login')
    })
  }

  const fetchCmdqDataCount = async () => {
    const onehour = 60 * 60 * 1000
    const currentTime: any = new Date()

    const allcmdqListDateLocalStg: any = localStorage.getItem('allcmdqListDate')
    const allcmdqListLocalStg: any = localStorage.getItem('allcmdqList')
    let convertToDate: any = new Date()
    let allcmdqListLocalStgJson: any = []

    if (allcmdqListDateLocalStg !== null) {
      convertToDate = new Date(allcmdqListDateLocalStg)
    }

    if (allcmdqListLocalStg !== null) {
      allcmdqListLocalStgJson = JSON.parse(allcmdqListLocalStg)
    }

    if (((currentTime - convertToDate) > onehour) || allcmdqListLocalStg === null) {
      try {
        getReports(true)

        const cmdqData: any = await API.graphql({
          query: listCMDQS,
          variables: { limit: 30000 },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        })

        let nextToken: any = cmdqData.data.listCMDQS.nextToken
        let totalItems: any = cmdqData.data.listCMDQS.items.length
        let cmdqList = cmdqData.data.listCMDQS.items

        while (nextToken != null) {
          let newPage: any = await API.graphql({
            query: listCMDQS,
            variables: { 
              limit: 30000,
              nextToken: nextToken
            },
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM
          })
          nextToken = newPage.data.listCMDQS.nextToken ? newPage.data.listCMDQS.nextToken : null
          totalItems = totalItems + newPage.data.listCMDQS.items.length
          cmdqList.push(...newPage.data.listCMDQS.items)
        }

        if (cmdqList && cmdqList.length >= 0) {
          localStorage.removeItem('allcmdqList');
          localStorage.removeItem('allcmdqListDate');

          const apiListDate = new Date()
          localStorage.setItem('allcmdqList', JSON.stringify(cmdqList));
          localStorage.setItem('allcmdqListDate', apiListDate.toString());
          
          setAllCmdqDatas(cmdqList)
          setCmdqDataCount(totalItems)
        }
      } catch (error) {
        // console.log('Error on fetching cmdq data count', error)
      }
    } else {
      setAllCmdqDatas(allcmdqListLocalStgJson)
      setCmdqDataCount(allcmdqListLocalStgJson.length)
      getReports(false)
    }
  }

  function filterByLOB(list: any, keyGetter: any) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    
    return map;
  }

  const fetchCmdqDatas = async () => {
    try {
      const grouped = filterByLOB(allCmdqDatas, (cacheFilter: { LOB: any; }) => cacheFilter.LOB);
      setCmdqDatas(grouped.get(filterData.LOB))
    } catch (error) {
      console.log(error);
    }
    // try {
    //   let filter = {
    //     LOB: {
    //       eq: filterData.LOB
    //     }
    //   };

    //   const cmdqData: any = await API.graphql({
    //     query: listCMDQS,
    //     variables: { filter: filter },
    //     authMode: GRAPHQL_AUTH_MODE.AWS_IAM
    //   })
    //   const cmdqList = cmdqData.data.listCMDQS.items
    //   setCmdqDatas(cmdqList)
    // } catch (error) {
    //   // console.log('Error on fetching cmdq datas', error)
    // }
  }

  const monthOptvalue = Months.map((month) => {
    return <option key={month} value={month}>{month}</option>
  })
  
  const yearOptvalue = Years.map((year) => {
    return <option key={year} value={year}>{year}</option>
  })

  const lobOptvalue = LOB.map((opt) => {
    return <option key={opt} value={opt}>{opt}</option>
  })

  const subsdOptvalue = Subsidiary.map((opt) => {
    return <option key={opt} value={opt}>{opt}</option>
  })

  const dataViewOptvalue = DataView.map((opt) => {
    return <option key={opt} value={opt}>{opt}</option>
  })

  const handleFilter = (e: { target: { id: any; value: any; }; }) => {
    const { id, value } = e.target
    setFilterData(prevState => ({
      ...prevState,
      [id]: value
    }))
    // reset selected data array
    while (cmdqSelectedDatas.length > 0) {
      cmdqSelectedDatas.pop()
    }
    setCmdqSelectedDatas(cmdqSelectedDatas)
  }

  const handleSearch = (e: { target: { id: any; value: any; }; }) => {
    const { value } = e.target

    let cmdqMapArr: any = []
    let cmdqFilteredArr: any = []

    for (const value of Object.values(cmdqDatas)) {
      cmdqMapArr.push(value)
    }

    for (const obj of cmdqMapArr) {
      try {
        if (obj.name.toLowerCase().includes(value.toLowerCase())) {
          cmdqFilteredArr.push(obj)
        }
      } catch (err) {

      }
    }
    setCmdqFilteredDatas(cmdqFilteredArr)
    setFilterDataName(value)
  }

  const handlePagination = () => {
    // console.log("handle pagination filter")
  }

  const handleDownloadAll = async () => {
    try {
      // console.log("SUBMIT filter")

      setIsDownloadingPDF(true)
      setPdfDownloadStatusError(false)
      setPdfDownloadStatusMsg("")

      if (filterDataName.length > 0) {
        // console.log("cmdqFilteredDatas")
        // console.log(cmdqFilteredDatas)
        cmdqFilteredDatas.forEach(element => {
          let data: any = element
          const filename_short_name = data.name.substring(0, 10)
          const lob_split = data.LOB.split(" ").join("").toLowerCase()
          const lob_shortname = lob_split.split("&").join("")
          const output_filename = `CMDQ Result - ${filename_short_name} - ${data.staffID}.pdf`
          const filepath = `${lob_shortname}/${output_filename}`

          Storage.get(`${filepath}`, { download: true, expires: 60, contentType: 'application/pdf' })
            .then(result => {
              let response: any;

              response = result
              // console.log(response.Body)
              const url = window.URL.createObjectURL(new Blob([response.Body]));
              // console.log("url")
              // console.log(url)
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${filepath}`);
              document.body.appendChild(link);
              link.click();
              // link.parentNode.removeChild(link);
              setIsDownloadingPDF(false)
            })
            .catch(err => {
              setPdfDownloadStatusError(true)
              setPdfDownloadStatusMsg("Error fetching and downloading PDF from servers")
              setIsDownloadingPDF(false)
            });
        });
      } else {
        // console.log("cmdqDatas")
        // console.log(cmdqDatas)
        cmdqDatas.forEach(element => {
          let data: any = element
          const filename_short_name = data.name.substring(0, 10)
          const lob_split = data.LOB.split(" ").join("").toLowerCase()
          const lob_shortname = lob_split.split("&").join("")
          const output_filename = `CMDQ Result - ${filename_short_name} - ${data.staffID}.pdf`
          const filepath = `${lob_shortname}/${output_filename}`

          Storage.get(`${filepath}`, { download: true, expires: 60, contentType: 'application/pdf' })
            .then(result => {
              let response: any;

              response = result
              // console.log(response.Body)
              const url = window.URL.createObjectURL(new Blob([response.Body]));
              // console.log("url")
              // console.log(url)
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${filepath}`);
              document.body.appendChild(link);
              link.click();
              // link.parentNode.removeChild(link);
              setIsDownloadingPDF(false)
            })
            .catch(err => {
              setPdfDownloadStatusError(true)
              setPdfDownloadStatusMsg("Error fetching and downloading PDF from servers")
              setIsDownloadingPDF(false)
            });
        });
      }
    } catch (error) {
      setPdfDownloadStatusError(true)
      setPdfDownloadStatusMsg("Error fetching and downloading PDF from servers")
      setIsDownloadingPDF(false)
    }
  }

  const handleDownloadSelected = async () => {
    setIsDownloadingPDF(true)
    setPdfDownloadStatusError(false)
    setPdfDownloadStatusMsg("")
    let elementArr: any = []
    cmdqSelectedDatas.forEach(element => {
      elementArr.push(element)
    });

    if (elementArr.length > 0) {
      elementArr.forEach((element: { filename: any; }) => {
        Storage.get(`${element.filename}`, { download: true, expires: 60, contentType: 'application/pdf' })
          .then(result => {
            let response: any;

            response = result
            // console.log(response.Body)
            const url = window.URL.createObjectURL(new Blob([response.Body]));
            // console.log("url")
            // console.log(url)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${element.filename}`);
            document.body.appendChild(link);
            link.click();
            // link.parentNode.removeChild(link);
            setIsDownloadingPDF(false)
          })
          .catch(err => {
            setPdfDownloadStatusError(true)
            setPdfDownloadStatusMsg("Error fetching and downloading PDF from servers")
            setIsDownloadingPDF(false)
          });
      });
    } else {
      setPdfDownloadStatusError(true)
      setPdfDownloadStatusMsg("Please select first the data first")
    }
  }

  const selectedDatas = (selectedData: []) => {
    try {
      // console.log("selectedData")
      // console.log(selectedData)
      setCmdqSelectedDatas(selectedData)
    } catch (error) {
      // console.log("Get data from child error : " + error)
    }
  }

  const handleDashboardView: any = (pagetype: string) => {
    if (pagetype === 'datatable') {
      setIsDataTableView(true)
      setIsReportTableView(false)
    } else if (pagetype === 'excelreport') {
      setIsDataTableView(false)
      setIsReportTableView(true)
    }
  }

  const handleMonthYearFilter = (e: { target: { id: any; value: any; }; }) => {
    const { id, value } = e.target
    setFilterMonthYear(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  const generateExcelReport = async () => {
    try {
      setIsShowGenReportMessage(false)
      let endMonth = 'Dec'
      let endYear = '2025'
      // if (filterMonthYear.month === 'Dec') {
      //   endMonth = 'Jan'
      //   endYear = Years[Years.indexOf(filterMonthYear.year) + 1]
      // } else {
      //   endMonth = Months[Months.indexOf(filterMonthYear.month) + 1]
      //   endYear = filterMonthYear.year
      // }
      
      let isCallApi = await appService.generateReport({
          startMonth: filterMonthYear.month,
          startYear: filterMonthYear.year,
          endMonth: endMonth,
          endYear: endYear,
      });

      setIsGenerateBtnEnable(false)
      if (isCallApi) setIsShowGenReportMessage(true)
    } catch (err) {
      console.log("error===");
      console.log(err); 
    }
  }

  const isGenerateExcelButtonEnable = (reports: any) => {
    try {
      setIsGenerateBtnEnable(false)
      let tempBtn = false;
      if (isShowGenReportMessage) {
        setIsGenerateBtnEnable(false)
      }
      if (reports && reports.length > 0) {
        let report: any;
        for (report of reports) {
          let filenameSplit = report.split(' ');
          let filenameDate = new Date(filenameSplit[0] + filenameSplit[1] + filenameSplit[2])
          let currentDate = new Date()

          if (currentDate.getTime() - filenameDate.getTime() < 86400000) {
            tempBtn = false
            break;
          } else {
            tempBtn = true
          }
        }

        if (tempBtn) {
          setIsGenerateBtnEnable(true)
        }
      } else {
        setIsGenerateBtnEnable(true)
      }
    } catch (err) {
      setIsGenerateBtnEnable(false)
      console.log("error===");
      console.log(err); 
    }
  }

  const renderTable = (filterName: string, cmdqDatas: never[]) => {
    if (filterName.length > 0) {
      return <AdminTable tableBodyDatas={cmdqFilteredDatas} sendData={selectedDatas} />
    } else {
      return <AdminTable tableBodyDatas={cmdqDatas} sendData={selectedDatas} />
    }
  }

  const getReports = async (isCallApi: boolean) => {
    try {
      setExcelReportDatas([])
      let excelReports: any;
      const cmdqExcelReportsLocalStg: any = localStorage.getItem('cmdqExcelReports')
      
      if (isCallApi || cmdqExcelReportsLocalStg === null) {
        localStorage.removeItem('cmdqExcelReports');
        excelReports = await appService.getReports();
        localStorage.setItem('cmdqExcelReports', JSON.stringify(excelReports));
      } else {
        excelReports = JSON.parse(cmdqExcelReportsLocalStg);
      }

      if (excelReports && excelReports.filename) {
        setExcelReportDatas(excelReports.filename)
        isGenerateExcelButtonEnable(excelReports.filename)
        let excelReportsMap= excelReports.filename.map((report: any, index: any) => {
          let url = `https://cmdq-user-data.s3.ap-southeast-1.amazonaws.com/${report}`
          return <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td className="text-left"><a href={url} target="_blank">{report}</a></td>
          </tr>
        })
        
        setExcelReportData(excelReportsMap)
      } else {
        return [];
      }

    } catch (err) {
      console.log("error===");
      console.log(err); 
    }
  }

  const refreshReports = () => {
    getReports(true)
    return true
  }
  
  return <div className="container-fluid text-left min-vh-100 bg-aliceblue">
    <div className="row">
      <div className="col-md-2">
        <AdminSideBar adminName={adminUsrName} />
      </div>
      <div className="col-md-10">
        <div className="row d-flex justify-content-center mt-100 admin-header">
          <div className="col-lg-4">
            <CircularProgress
              strokeWidth={10}
              sqSize={200}
              totalCount={cmdqDataCount} />
          </div>
          <div className="col-lg-8 align-self-center">
            <h3 className="text-right">Cornell Musculoskeletal Discomfort Questionnaires (CMDQ)</h3>
            <p className="text-right">Assessment Report</p>
          </div>
        </div>

        <br />

        <div className="btn-group" role="group">
          <input type="radio" className="btn-check" name="btnradio" id="btnRadioDataTable" autoComplete="off" checked={isDataTableView} onClick={() => handleDashboardView('datatable')} />
          <label className="btn btn-outline-primary" htmlFor="btnRadioDataTable">Data Table</label>

          <input type="radio" className="btn-check" name="btnradio" id="btnRadioReportTable" autoComplete="off" checked={isReportTableView} onClick={() => handleDashboardView('excelreport')}/>
          <label className="btn btn-outline-primary" htmlFor="btnRadioReportTable">Excel Report</label>
        </div>

        <br />
        <br />

        {isDataTableView && 
          <div>
            <form className="admin-filter">
              <div className="row align-items-center">
                <div className="col-lg-5 align-items-center">
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={filterDataName} onChange={handleSearch} />
                </div>
                <div className="col-lg-4 display-flex align-items-center">
                  <span className="min-width-fit-content margin-right-5px">Filter by : </span>
                  <select id="LOB" className="form-select" aria-label="Default select example" value={filterData.LOB} onChange={handleFilter}>
                    <option value="" disabled hidden>LOB/Subsidaries</option>
                    <optgroup label="LOB">{lobOptvalue}</optgroup>
                    <optgroup label="Subsidiaries">{subsdOptvalue}</optgroup>
                  </select>
                </div>
                <div className="col-lg-2 display-flex">
                  {isDownloadingPDF ?
                    <button type="button" className="btn btn-primary btn-download-filter font-weight-bold" disabled>Downloading
                      <div className="spinner-border spinner-border-sm margin-left-1rem text-primary" role="status"></div>
                    </button>
                    :
                    <button type="button" className="btn btn-primary btn-download-filter font-weight-bold" disabled={btnDisabled || cmdqSelectedDatas.length === 0} onClick={handleDownloadSelected}>Download Selected</button>
                  }
                </div>
              </div>
              <br />
              {pdfDownloadStatusError && <div className="alert alert-danger" role="alert">{pdfDownloadStatusMsg}</div>}
            </form>

            <h4>REPORT</h4>
            {renderTable(filterDataName, cmdqDatas)}
          </div>
        }

        {isReportTableView && 
          <div>
            <form className="admin-filter">
              <div className="row align-items-center">
                {/* <div className="col-lg-3 display-flex align-items-center">
                  <span className="min-width-fit-content margin-right-5px">Month :</span>
                  <select id="month" className="form-select" aria-label="Default select example" value={filterMonthYear.month} onChange={handleMonthYearFilter} >
                    <optgroup label="Month">{monthOptvalue}</optgroup>
                  </select>
                </div>
                <div className="col-lg-3 display-flex align-items-center">
                  <span className="min-width-fit-content margin-right-5px">Year : </span>
                  <select id="year" className="form-select" aria-label="Default select example" value={filterMonthYear.year} onChange={handleMonthYearFilter} >
                    <optgroup label="Year">{yearOptvalue}</optgroup>
                  </select>
                </div> */}
                <div className="col-lg-2 display-flex">
                  {isGenerateBtnEnable ?
                    <button type="button" className="btn btn-primary btn-download-filter font-weight-bold" onClick={generateExcelReport}>Generate Report</button>
                    :
                    // <button type="button" className="btn btn-primary btn-download-filter font-weight-bold" onClick={generateExcelReport}>Generate Report</button>
                    <button type="button" className="btn btn-primary btn-download-filter font-weight-bold" disabled>Generating Report</button>
                  }
                </div>
                <div className="col-lg-10 display-flex">
                  You can only generate report once in a day.
                </div>
              </div>
              <br/>
              {isShowGenReportMessage && <div className="alert alert-info" role="alert">
                Successfully trigger the report generation, it may take up to 30 minutes to generate the report
              </div>}
            </form>

            <div className="d-flex justify-content-between">
              <div>
                <h4>Excel Report</h4>
              </div>
              <div>
                <button type="button" className="btn btn-outline-primary font-weight-bold" onClick={refreshReports}>Refresh report</button>
              </div>
            </div>
            {excelReportDatas && excelReportDatas.length > 0 ?
              <div className="report-table">
                  <table className="table table-hover text-left">
                      <thead>
                          <tr>
                              <th scope="col" className="col-2">NO</th>
                              <th scope="col" className="col-10">FILENAME</th>
                          </tr>
                      </thead>
                      <tbody>
                          {excelReportData}
                      </tbody>
                  </table>
              </div>
              :
              <div className="alert alert-info" role="alert">
                No data return from file storage
              </div>
            }
            
          </div>
        }
      </div>
    </div>
  </div>
}

export default AdminDashboard
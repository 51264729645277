import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../App.css'
import FormField from './FormField'
import Background from "../assets/BG-2.png"
import TMLogo from "../assets/TM-Logo-2023.png"

const LOB = [
    "Business Innovation Group (BIG)",
    "Central Function",
    "Group Information Technology & Digital (GITD)",
    "Group Network Technology (GNT)",
    "Group Customer Experience (GCX)",
    "Support Business",
    "TM One",
    "TM Wholesale",
    "Unifi",
]

const Subsidiary = [
    "Acasia",
    "Fiberail",
    "Fibercomm",
    "GITN Sdn Bhd",
    "Menara Kuala Lumpur ",
    "PT. VADS Indonesia",
    "Telekom Malaysia (AU) Pty",
    "Telekom Malaysia (HK) Ltd",
    "Telekom Malaysia (UK) Ltd",
    "Telekom Malaysia (US) Inc",
    "Telekom Malaysia(S) P Ltd",
    "TM Applied Business Sdn Bhd",
    "TM Info-Media Sdn Bhd",
    "TM R&D Sdn Bhd",
    "TM Sales & Services Sdn Bhd (TSSSB)",
    "TMF Autolease Sdn Bhd",
    "Universiti Telekom Sdn Bhd",
    "VADS Berhad",
    "VADS Business Process",
    "Webe Digital Sdn Bhd"
]

const LoginForm = () => {
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [state, setState] = useState({
        name: "",
        staffID: "",
        email: "",
        LOB: ""
    })

    const history = useHistory();

    useEffect(() => {
        if (state.name && state.staffID && validateEmail() && state.LOB) {
            setBtnDisabled(false)
        } else {
            setBtnDisabled(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    function validateEmail() {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(state.email)) {
            return (true)
        }
        return (false)
    }

    const handleChange = (e: { target: { id: any; value: any; }; }) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSubmitClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (state.name.length && state.staffID.length && state.email.length) {
            const payload = {
                "name": state.name,
                "staffID": state.staffID,
                "email": state.email,
                "LOB": state.LOB,
            }
            console.log(payload)
            history.push({
                pathname: '/cmdq',
                state: { payload }
            })
        } else {
            // console.log("submit error")
        }
    }

    const lobOptvalue = LOB.map((opt) => {
        return <option key={opt} value={opt}>{opt}</option>
    })

    const subsdOptvalue = Subsidiary.map((opt) => {
        return <option key={opt} value={opt}>{opt}</option>
    })

    return <div className="container-fluid text-left height-100-vh normal-login-bg" style={{ backgroundImage: `url(${Background})` }}>
        <div className="row position-relative top-20 left-10-desktop">
            <div className="col-md-3">
                <div className="card card-shadow padding-1rem">
                    <div className="card-body">
                        <h3 className="card-title text-left"><img src={TMLogo} alt="TM Logo" width="130" /></h3>
                        <h3 className="card-title">Hello,</h3>
                        <h3 className="card-title">Welcome</h3>
                        <form onSubmit={handleSubmitClick}>
                            <FormField id="name" type="text" placeholder="Fullname as per IC" value={state.name} onchange={handleChange} />
                            <br />
                            <FormField id="staffID" type="text" placeholder="TM ID" value={state.staffID} onchange={handleChange} />
                            <br />
                            <FormField id="email" type="email" placeholder="TM Email Address" value={state.email} onchange={handleChange} />
                            <br />
                            <select id="LOB" className="form-select" aria-label="Default select example" value={state.LOB} onChange={handleChange}>
                                <option value="" disabled hidden>LOB/Subsidaries</option>
                                <optgroup label="LOB">{lobOptvalue}</optgroup>
                                <optgroup label="Subsidiaries">{subsdOptvalue}</optgroup>
                            </select>
                            <br />
                            {/* <button
                                type="submit"
                                className="btn btn-primary width-full padding-1rem font-weight-bold"
                                onClick={handleSubmitClick}
                                disabled={btnDisabled}
                            >LOGIN</button> */}
                            <button
                                type="submit"
                                className="btn btn-primary width-full padding-1rem font-weight-bold"
                                disabled={btnDisabled}
                            >LOGIN</button>
                            <br />
                        </form>
                        <Link className="btn btn-outline-primary margin-top-1rem width-full padding-1rem font-weight-bold" to="/admin-login">Admin Login</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginForm
import './CMDQ.css'
import '../../App.css'
import React, { useEffect, useState } from 'react';
import CMDQTableTDRadio from './CMDQTableTDRadio'

export interface Props {
  sendData: any
}

const CMDQTableForm1 = ({ sendData }: Props) => {
  const [isFormCompleted, setIsFormCompleted] = useState(false)
  const [tableData, setTableData] = useState({
    PENeck: "",
    PSNeck: "",
    WINeck: "",
    PEShoulderRight: "",
    PSShoulderRight: "",
    WIShoulderRight: "",
    PEShoulderLeft: "",
    PSShoulderLeft: "",
    WIShoulderLeft: "",
    PEUpperBack: "",
    PSUpperBack: "",
    WIUpperBack: "",
    PEUpperArmLeft: "",
    PSUpperArmLeft: "",
    WIUpperArmLeft: "",
    PEUpperArmRight: "",
    PSUpperArmRight: "",
    WIUpperArmRight: "",
    PEForearmLeft: "",
    PSForearmLeft: "",
    WIForearmLeft: "",
    PEForearmRight: "",
    PSForearmRight: "",
    WIForearmRight: "",
    PEWristLeft: "",
    PSWristLeft: "",
    WIWristLeft: "",
    PEWristRight: "",
    PSWristRight: "",
    WIWristRight: ""
  })

  useEffect(() => {
    let checkIsFormCompleted = false
    let dataSendToParent = {}

    for (const value of Object.values(tableData)) {
      if (isNaN(parseInt(value))) {
        checkIsFormCompleted = false;
        break
      }
      checkIsFormCompleted = true;
    }

    checkIsFormCompleted ? setIsFormCompleted(true) : setIsFormCompleted(false)

    if (isFormCompleted) {
      dataSendToParent = {
        isBtnDisable: true,
        tableData1: tableData
      }
      sendData(dataSendToParent)
    } else {
      dataSendToParent = {
        isBtnDisable: false,
        tableData1: {}
      }
      sendData(dataSendToParent)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, isFormCompleted])

  const handleChange = (e: { target: { id: string; value: string; }; }) => {
    const { id, value } = e.target
    let isPainExp = id.substring(0,2)
    
    if (isPainExp === "PE" && value === "1") {
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "") {
        painSevValue = "0"
      }
      if (workIntValue === "") {
        workIntValue = "0"
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else if (isPainExp === "PE" && value !== "1") {

      // checkPsPeWiValue(bodyPart, value)
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "0") {
        painSevValue = ""
      }
      if (workIntValue === "0") {
        workIntValue = ""
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else {
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
      }))
    }
  }

  const checkPsValue = (bodyPart: string) => {
    let painSevValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          painSevValue = value
          break;      
        default:
          break;
      }
    }
    return painSevValue
  }

  const checkWiValue = (bodyPart: string) => {
    let workIntValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          workIntValue = value
          break;      
        default:
          break;
      }
    }
    return workIntValue
  }

  return (
    <tbody>
      <tr>
        <th scope="row">1.Neck</th>
        <CMDQTableTDRadio id="PENeck" value="1" checked={tableData.PENeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PENeck" value="2" checked={tableData.PENeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PENeck" value="3" checked={tableData.PENeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PENeck" value="4" checked={tableData.PENeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PENeck" value="5" checked={tableData.PENeck} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSNeck" value="1" checked={tableData.PSNeck} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSNeck" value="2" checked={tableData.PSNeck} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSNeck" value="3" checked={tableData.PSNeck} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WINeck" value="1" checked={tableData.WINeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WINeck" value="2" checked={tableData.WINeck} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WINeck" value="3" checked={tableData.WINeck} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">2.Right Shoulder</th>
        <CMDQTableTDRadio id="PEShoulderRight" value="1" checked={tableData.PEShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderRight" value="2" checked={tableData.PEShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderRight" value="3" checked={tableData.PEShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderRight" value="4" checked={tableData.PEShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderRight" value="5" checked={tableData.PEShoulderRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSShoulderRight" value="1" checked={tableData.PSShoulderRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSShoulderRight" value="2" checked={tableData.PSShoulderRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSShoulderRight" value="3" checked={tableData.PSShoulderRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIShoulderRight" value="1" checked={tableData.WIShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIShoulderRight" value="2" checked={tableData.WIShoulderRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIShoulderRight" value="3" checked={tableData.WIShoulderRight} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">3.Left Shoulder</th>
        <CMDQTableTDRadio id="PEShoulderLeft" value="1" checked={tableData.PEShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderLeft" value="2" checked={tableData.PEShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderLeft" value="3" checked={tableData.PEShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderLeft" value="4" checked={tableData.PEShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEShoulderLeft" value="5" checked={tableData.PEShoulderLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSShoulderLeft" value="1" checked={tableData.PSShoulderLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSShoulderLeft" value="2" checked={tableData.PSShoulderLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSShoulderLeft" value="3" checked={tableData.PSShoulderLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIShoulderLeft" value="1" checked={tableData.WIShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIShoulderLeft" value="2" checked={tableData.WIShoulderLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIShoulderLeft" value="3" checked={tableData.WIShoulderLeft} onchange={handleChange} classname="background-orange"/>
      </tr>

      <tr>
        <th scope="row">4.Upper Back</th>
        <CMDQTableTDRadio id="PEUpperBack" value="1" checked={tableData.PEUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperBack" value="2" checked={tableData.PEUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperBack" value="3" checked={tableData.PEUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperBack" value="4" checked={tableData.PEUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperBack" value="5" checked={tableData.PEUpperBack} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSUpperBack" value="1" checked={tableData.PSUpperBack} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperBack" value="2" checked={tableData.PSUpperBack} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperBack" value="3" checked={tableData.PSUpperBack} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIUpperBack" value="1" checked={tableData.WIUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperBack" value="2" checked={tableData.WIUpperBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperBack" value="3" checked={tableData.WIUpperBack} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">5.Left Upper Arm</th>
        <CMDQTableTDRadio id="PEUpperArmLeft" value="1" checked={tableData.PEUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmLeft" value="2" checked={tableData.PEUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmLeft" value="3" checked={tableData.PEUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmLeft" value="4" checked={tableData.PEUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmLeft" value="5" checked={tableData.PEUpperArmLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSUpperArmLeft" value="1" checked={tableData.PSUpperArmLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperArmLeft" value="2" checked={tableData.PSUpperArmLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperArmLeft" value="3" checked={tableData.PSUpperArmLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIUpperArmLeft" value="1" checked={tableData.WIUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperArmLeft" value="2" checked={tableData.WIUpperArmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperArmLeft" value="3" checked={tableData.WIUpperArmLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">6.Right Upper Arm</th>
        <CMDQTableTDRadio id="PEUpperArmRight" value="1" checked={tableData.PEUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmRight" value="2" checked={tableData.PEUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmRight" value="3" checked={tableData.PEUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmRight" value="4" checked={tableData.PEUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEUpperArmRight" value="5" checked={tableData.PEUpperArmRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSUpperArmRight" value="1" checked={tableData.PSUpperArmRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperArmRight" value="2" checked={tableData.PSUpperArmRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSUpperArmRight" value="3" checked={tableData.PSUpperArmRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIUpperArmRight" value="1" checked={tableData.WIUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperArmRight" value="2" checked={tableData.WIUpperArmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIUpperArmRight" value="3" checked={tableData.WIUpperArmRight} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">7.Left Forearm</th>
        <CMDQTableTDRadio id="PEForearmLeft" value="1" checked={tableData.PEForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmLeft" value="2" checked={tableData.PEForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmLeft" value="3" checked={tableData.PEForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmLeft" value="4" checked={tableData.PEForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmLeft" value="5" checked={tableData.PEForearmLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSForearmLeft" value="1" checked={tableData.PSForearmLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSForearmLeft" value="2" checked={tableData.PSForearmLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSForearmLeft" value="3" checked={tableData.PSForearmLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIForearmLeft" value="1" checked={tableData.WIForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIForearmLeft" value="2" checked={tableData.WIForearmLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIForearmLeft" value="3" checked={tableData.WIForearmLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">8.Right Forearm</th>
        <CMDQTableTDRadio id="PEForearmRight" value="1" checked={tableData.PEForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmRight" value="2" checked={tableData.PEForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmRight" value="3" checked={tableData.PEForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmRight" value="4" checked={tableData.PEForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEForearmRight" value="5" checked={tableData.PEForearmRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSForearmRight" value="1" checked={tableData.PSForearmRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSForearmRight" value="2" checked={tableData.PSForearmRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSForearmRight" value="3" checked={tableData.PSForearmRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIForearmRight" value="1" checked={tableData.WIForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIForearmRight" value="2" checked={tableData.WIForearmRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIForearmRight" value="3" checked={tableData.WIForearmRight} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">9.Left Wrist</th>
        <CMDQTableTDRadio id="PEWristLeft" value="1" checked={tableData.PEWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristLeft" value="2" checked={tableData.PEWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristLeft" value="3" checked={tableData.PEWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristLeft" value="4" checked={tableData.PEWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristLeft" value="5" checked={tableData.PEWristLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSWristLeft" value="1" checked={tableData.PSWristLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSWristLeft" value="2" checked={tableData.PSWristLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSWristLeft" value="3" checked={tableData.PSWristLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIWristLeft" value="1" checked={tableData.WIWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIWristLeft" value="2" checked={tableData.WIWristLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIWristLeft" value="3" checked={tableData.WIWristLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">10.Right Wrist</th>
        <CMDQTableTDRadio id="PEWristRight" value="1" checked={tableData.PEWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristRight" value="2" checked={tableData.PEWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristRight" value="3" checked={tableData.PEWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristRight" value="4" checked={tableData.PEWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEWristRight" value="5" checked={tableData.PEWristRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSWristRight" value="1" checked={tableData.PSWristRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSWristRight" value="2" checked={tableData.PSWristRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSWristRight" value="3" checked={tableData.PSWristRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIWristRight" value="1" checked={tableData.WIWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIWristRight" value="2" checked={tableData.WIWristRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIWristRight" value="3" checked={tableData.WIWristRight} onchange={handleChange} classname="background-orange"/>
      </tr>
    </tbody>
  );
};

export default CMDQTableForm1
import './CMDQ.css'
import '../../App.css'

export interface Props {
    staffName: string;
    staffID: string;
    staffEmail: string;
    staffLOB: string;
    currentPage: number;
}

const CMDQHeader = ({staffName, staffID, staffEmail, staffLOB, currentPage} : Props) => {
    const headerText = (whichPage: number) => {
        switch (whichPage) {
            case 1:
                return <h3>Cornell Musculoskeletal Discomfort Questionnaires (CMDQ) Part I - Body</h3>
            case 2:
                return <h3>Cornell Musculoskeletal Discomfort Questionnaires (CMDQ) Part II - Body</h3>
            case 3:
                return <h3>Cornell Musculoskeletal Discomfort Questionnaires (CMDQ) - Right Hand</h3>
            case 4:
                return <h3>Cornell Musculoskeletal Discomfort Questionnaires (CMDQ) - Left Hand</h3>
            default:
                break;
        }
    }
    return (
        <div className="row justify-content-center">
            <div className="cmdq-header display-block-mobile">
                <div className="cmdq-sub-header">
                    <span>Name: <span className="font-weight-bold">{staffName}</span></span>
                </div>
                <div className="cmdq-sub-header">
                    <span>ID: <span className="font-weight-bold">{staffID}</span></span>
                </div>
                <div className="cmdq-sub-header">
                    <span>Email: <span className="font-weight-bold">{staffEmail}</span></span>
                </div>
                <div className="cmdq-sub-header">
                    <span>LOB: <span className="font-weight-bold">{staffLOB}</span></span>
                </div>
            </div>
            <div className="row text-center">
                <span>
                    {headerText(currentPage)} 
                    The diagram below shows the approximate position of the body parts referred to in the questionnaire.<br/>
                    Please answer by marking the appropriate box
                </span>
            </div>
        </div>
    );
  };

export default CMDQHeader
import { useEffect, useState } from 'react'
import '../App.css'

export interface Props {
    tableBodyDatas: TableBodyData[];
    sendData: any;
}

export interface TableBodyData {
    id: string;
    name: string;
    staffID: string;
    LOB: string;
    updatedAt: string;
    createdAt: Date;
}

const AdminTable = ({ tableBodyDatas, sendData }: Props) => {
    const [checkedData, setCheckedData] = useState([])
    
    useEffect(() => {
        sendData(checkedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedData])

    const onchange = (e: { target: { id: string; value: string; checked: boolean}; })=> {
        const { id, value, checked } = e.target
        let chkData: any = [...checkedData]
        let selectedData = {
            id: id,
            filename: value
        }

        if (checked) {
            chkData.push(selectedData)
            setCheckedData(chkData)
        } else {
            for (const [index, data] of chkData.entries()) {
                if (data.id === id) {
                    chkData.splice(index, 1)
                    setCheckedData(chkData)
                } 
            }
        }
    }
    
    let tableBodyData: any;
    if (tableBodyDatas && tableBodyDatas.length > 0) {
        tableBodyData = tableBodyDatas.map((data, index) => {
            const filename_short_name = data.name.substring(0,10)
            const lob_split = data.LOB.split(" ").join("").toLowerCase()
            const lob_shortname = lob_split.split("&").join("")
            const output_filename = `CMDQ Result - ${filename_short_name} - ${data.staffID}.pdf`
            const filepath = `${lob_shortname}/${output_filename}`
            
            return <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td className="text-left">{data.name}</td>
                <td>{data.staffID}</td>
                <td>{new Date(data.updatedAt).toLocaleDateString()}</td>
                <td><input id={data.id} className="form-check-input" type="checkbox" value={filepath} onChange={onchange} /></td>
            </tr>
        })
    } 

    return <div>
        {tableBodyData && tableBodyData.length > 0 ?
            <div className="report-table">
                <table className="table table-hover text-center">
                    <thead>
                        <tr>
                            <th scope="col">NO</th>
                            <th scope="col" className="w-50 p-3">NAME</th>
                            <th scope="col">ID</th>
                            <th scope="col">COMPLETED DATE</th>
                            <th scope="col">MARK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBodyData}
                    </tbody>
                </table>
            </div>
            :
            <div className="alert alert-info" role="alert">
                No data return from DB, try changing the LOB/Subsidiaries
            </div>
        }
    </div>
}

export default AdminTable
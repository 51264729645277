import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginForm from './components/LoginForm'
import AdminLoginForm from './components/AdminLoginForm'
import AdminDashboard from './components/AdminDashboard'

import CMDQNewTable from './components/CMDQ/CMDQNewTable'
import { useEffect, useState } from 'react'
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)


function App() {
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    // console.log("is logged in? == " + loggedIn)
    AssessLoggedInState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AssessLoggedInState = () => {
    Auth.currentAuthenticatedUser().then(() => {
      setLoggedIn(true)
    }).catch(() => {
      setLoggedIn(false)
    })
  }

  const onSignIn = () => {
    setLoggedIn(true)
  }

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/admin-login">
            <AdminLoginForm onSignIn={onSignIn}/>
          </Route>
          <Route exact path="/admin-dashboard">
            <AdminDashboard />
          </Route>
          <Route exact path="/">
            <LoginForm />
          </Route>
          <Route exact path="/cmdq">
            <CMDQNewTable />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
import './CMDQ.css'
import '../../App.css'

const CMDQTableHead = () => {
    return (
        <thead>
            <tr>
                <th scope="col" className="border-top-hidden border-left-hidden"></th>
                <th colSpan={5} scope="col" className="background-orange">Pain Experience</th>
                <th colSpan={3} scope="col">Pain Severity</th>
                <th colSpan={3} scope="col" className="background-orange">Work Interference</th>
            </tr>
            <tr>
                <th scope="col" className="border-top-hidden border-left-hidden"></th>
                <th colSpan={5} scope="col" className="background-orange">During the last work week how often did you experience ache, pain, discomfort in:</th>
                <th colSpan={3} scope="col">If you experience ache, pain, discomfort, how uncomfortable was this?</th>
                <th colSpan={3} scope="col" className="background-orange">If you experience ache, pain, discomfort, did this interfere with your ability to work?</th>
            </tr>
            <tr>
                <th scope="col" className="border-top-hidden border-left-hidden"></th>
                <th scope="col" className="background-orange">Never</th>
                <th scope="col" className="background-orange">Once a week</th>
                <th scope="col" className="background-orange">3 - 4 times last week</th>
                <th scope="col" className="background-orange">Once everyday</th>
                <th scope="col" className="background-orange">Several times everyday</th>
                <th scope="col">Slightly uncomfortable</th>
                <th scope="col">Moderately uncomfortable</th>
                <th scope="col">Very uncomfortable</th>
                <th scope="col" className="background-orange">Not at all</th>
                <th scope="col" className="background-orange">Slightly interfered</th>
                <th scope="col" className="background-orange">Substantially interfered</th>
            </tr>
        </thead>
    );
};

export default CMDQTableHead
import { useHistory } from 'react-router-dom'
import { IoPersonCircle } from "react-icons/io5";
import './Admin/Admin.css'
import Amplify, { Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
import Button from './Button';

Amplify.configure(awsconfig)

export interface Props {
    adminName: string;
}

const AdminSideBar = ({adminName} : Props) => {
    const history = useHistory()
    
    const signOut = async () => {
        try {
            await Auth.signOut()
            history.push('/admin-login')
        } catch (error) {
            console.log('Error signing out', error)
        }
    }

    return (
        <div className="sidebar color-white">
            <div className="width-full text-center">
                <h5><IoPersonCircle size="100" className="color-orange"/> Hello, {adminName}</h5>
            </div>
            {/* <Link to="/admin-login">Assessment</Link> */}
            <br />
            <Button classname="btn-primary width-full padding-1rem font-weight-bold" text="Logout" onclick={signOut}/>
        </div>
    );
  };

export default AdminSideBar
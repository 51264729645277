import './CMDQ.css'
import '../../App.css'
import Part1Img from "../../assets/Parts-1.png"
import Part2Img from "../../assets/Parts-2.png"
import Part3Img from "../../assets/Parts-3.png"
import Part4Img from "../../assets/Parts-4.png"
import CMDQHeader from './CMDQHeader'
import CMDQTableHead from './CMDQTableHead'
import CMDQTableForm1 from './CMDQTableForm1'
import CMDQTableForm2 from './CMDQTableForm2'
import CMDQTableForm3 from './CMDQTableForm3'
import CMDQTableForm4 from './CMDQTableForm4'
import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { createCMDQ } from '../../graphql/mutations'

interface PayloadState {
    payload: {
        name: string
        staffID: string
        email: string
        LOB: string
    }
}

const CMDQNewTable = () => {
    const location = useLocation()
    const state = location.state as PayloadState;
    const [currentPage, setCurrentPage] = useState(1)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [isPDFDownload, setIsPDFDownload] = useState(false)
    const [pdfFilename, setPDFFilename] = useState('')
    const [tableData1, setTableData1] = useState({})
    const [tableData2, setTableData2] = useState({})
    const [tableData3, setTableData3] = useState({})
    const [tableData4, setTableData4] = useState({})
    const history = useHistory()

    const tableFormData = (formCompleted: { isBtnDisable: any, tableData1: any, tableData2: any, tableData3: any, tableData4: any }) => {
        try {
            formCompleted.isBtnDisable ? setBtnDisabled(false) : setBtnDisabled(true)
            if (formCompleted.tableData1 && Object.keys(formCompleted.tableData1).length > 0) {
                setTableData1(formCompleted.tableData1)
            }
            if (formCompleted.tableData2 && Object.keys(formCompleted.tableData2).length > 0) {
                setTableData2(formCompleted.tableData2)
            }
            if (formCompleted.tableData3 && Object.keys(formCompleted.tableData3).length > 0) {
                setTableData3(formCompleted.tableData3)
            }
            if (formCompleted.tableData4 && Object.keys(formCompleted.tableData4).length > 0) {
                setTableData4(formCompleted.tableData4)
            }
        } catch (error) {
            // console.log("Get data from child error : " + error)
        }
    }

    let staffName = ""
    let staffID = ""
    let staffEmail = ""
    let staffLOB = ""

    // useEffect(() => {
    //     let storedPage = localStorage.getItem("CMDQPage")
    //     if (storedPage) {
    //         setCurrentPage(parseInt(storedPage))
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
        if (state === undefined) {
            history.push('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    try {
        staffName = state.payload.name
        staffID = state.payload.staffID
        staffEmail = state.payload.email
        staffLOB = state.payload.LOB
    } catch (err) {
        // console.log("Error reading user payload")
    }

    const renderPage = (whichPage: number) => {
        switch (whichPage) {
            case 1:
                return <CMDQTableForm1 sendData={tableFormData} />
            case 2:
                return <CMDQTableForm2 sendData={tableFormData} />
            case 3:
                return <CMDQTableForm3 sendData={tableFormData} />
            case 4:
                return <CMDQTableForm4 sendData={tableFormData} />
            default:
                break;
        }
    }

    const renderImg = (whichPage: number) => {
        switch (whichPage) {
            case 1:
                return <img src={Part1Img} alt="Body Part 1" className="cmdq-img" />
            case 2:
                return <img src={Part2Img} alt="Body Part 2" className="cmdq-img" />
            case 3:
                return <img src={Part3Img} alt="Body Part 3" className="cmdq-img" />
            case 4:
                return <img src={Part4Img} alt="Body Part 4" className="cmdq-img" />
            default:
                break;
        }
    }

    const tableBtnClick = () => {
        setCurrentPage(currentPage + 1)
    }

    const openPDF = () => {
        // console.log("OPEN PDF")
        // console.log(pdfFilename)
        window.open(pdfFilename, "_blank")
    }

    const formSubmit = async () => {
        setBtnDisabled(true)
        let jsonPayload: any = {}

        jsonPayload["name"] = staffName
        jsonPayload["email"] = staffEmail
        jsonPayload["staffID"] = staffID
        jsonPayload["LOB"] = staffLOB

        for (const [key, value] of Object.entries(tableData1)) {
            // console.log(key)
            // console.log(value)
            jsonPayload[key] = value
        }

        for (const [key, value] of Object.entries(tableData2)) {
            // console.log(key)
            // console.log(value)
            jsonPayload[key] = value
        }

        for (const [key, value] of Object.entries(tableData3)) {
            // console.log(key)
            // console.log(value)
            jsonPayload[key] = value
        }

        for (const [key, value] of Object.entries(tableData4)) {
            // console.log(key)
            // console.log(value)
            jsonPayload[key] = value
        }

        // console.log("submit jsonPayload")
        // console.log(jsonPayload)

        try {
            const cmdqData = await API.graphql({
                query: createCMDQ,
                variables: { input: jsonPayload },
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
            })
            setCurrentPage(5)
            localStorage.setItem("localCmdqData", JSON.stringify(cmdqData))
            localStorage.setItem("CMDQPage", currentPage.toString())
            setIsPDFDownload(true)
            fetch('https://prds20c3y7.execute-api.ap-southeast-1.amazonaws.com/Prod/pdf', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonPayload)
            }).then(response => {
                setIsPDFDownload(false)
                const filename_name = jsonPayload.name.split("/").join("")
                const filename_short_name = filename_name.substring(0,10)
                const lob_split = jsonPayload.LOB.split(" ").join("").toLowerCase()
                const lob_shortname = lob_split.split("&").join("")
                const output_filename = `CMDQ Result - ${filename_short_name} - ${jsonPayload.staffID}.pdf`
                // setPDFFilename(`https://cmdqpdfstage224127-dev.s3.ap-southeast-1.amazonaws.com/public/${lob_shortname}/${output_filename}`)
                // window.open(`https://cmdqpdfstage224127-dev.s3.ap-southeast-1.amazonaws.com/public/${lob_shortname}/${output_filename}`, "_blank")
                setPDFFilename(`https://cmdqpdfstage231313-prod.s3.ap-southeast-1.amazonaws.com/public/${lob_shortname}/${output_filename}`)
                window.open(`https://cmdqpdfstage231313-prod.s3.ap-southeast-1.amazonaws.com/public/${lob_shortname}/${output_filename}`, "_blank")
            }).catch(error => {
                setIsPDFDownload(false)
                console.log("error==")
                console.log(error)
            })
        } catch (error) {
            setBtnDisabled(false)
            console.log("Error on creating cmdq data", error)
        }
    }

    return (
        <div>
            {currentPage === 5 ?
            <div className="row text-center justify-content-center cmdq-style margin-0">
                <h4 className="margin-5rem">Thank you for completing this assessment. Click on the button below to open and download your PDF result within 3 minutes to avoid any distruptions. Please upload this downloaded file in the TM Learn portal and follow the steps given. Thank you.
                    <br />
                    <br />
                    {isPDFDownload ? 
                        <button type="button" className="btn btn-light" disabled>
                            Processing
                            <div className="spinner-border spinner-border-sm margin-left-1rem text-primary" role="status"></div>
                        </button>                
                    :
                        <button type="button" className="btn btn-light" onClick={openPDF}>
                            Click here to open PDF Result
                        </button>
                    }
                </h4>
            </div>
            :
            <div className="row justify-content-center padding-0 cmdq-style margin-0">
                <CMDQHeader staffName={staffName} staffID={staffID} staffEmail={staffEmail} staffLOB={staffLOB} currentPage={currentPage} />
                <div className="col-md-2 padding-0 cmdq-img-center-mobile">
                    {renderImg(currentPage)}
                </div>
                <div className="col-md-10 padding-0 max-width-70rem">
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered cmdq-table">
                            <CMDQTableHead />
                            {renderPage(currentPage)}
                        </table>
                    </div>
                    {currentPage >= 4 ?
                        <button type="submit" className="btn btn-light float-right" disabled={btnDisabled} onClick={formSubmit}>Submit</button>
                        :
                        <button type="submit" className="btn btn-light float-right" disabled={btnDisabled} onClick={tableBtnClick}>NEXT</button>
                    }
                </div>
            </div>
            }
        </div>
    );
};

export default CMDQNewTable
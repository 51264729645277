import './CMDQ.css'
import '../../App.css'
import React, { useState, useEffect } from 'react';
import CMDQTableTDRadio from './CMDQTableTDRadio'

export interface Props {
  sendData: any
}

const CMDQTableForm4 = ({ sendData }: Props) => {
  const [isFormCompleted, setIsFormCompleted] = useState(false)
  const [tableData, setTableData] = useState({
    PELeftHandAreaA: "",
    PSLeftHandAreaA: "",
    WILeftHandAreaA: "",
    PELeftHandAreaB: "",
    PSLeftHandAreaB: "",
    WILeftHandAreaB: "",
    PELeftHandAreaC: "",
    PSLeftHandAreaC: "",
    WILeftHandAreaC: "",
    PELeftHandAreaD: "",
    PSLeftHandAreaD: "",
    WILeftHandAreaD: "",
    PELeftHandAreaE: "",
    PSLeftHandAreaE: "",
    WILeftHandAreaE: "",
    PELeftHandAreaF: "",
    PSLeftHandAreaF: "",
    WILeftHandAreaF: ""
  })

  useEffect(() => {
    let checkIsFormCompleted = false
    let dataSendToParent = {}

    for (const value of Object.values(tableData)) {
      if (isNaN(parseInt(value))) {
        checkIsFormCompleted = false;
        break
      }
      checkIsFormCompleted = true;
    }

    checkIsFormCompleted ? setIsFormCompleted(true) : setIsFormCompleted(false)

    if (isFormCompleted) {
      dataSendToParent = {
        isBtnDisable: true,
        tableData4: tableData
      }
      sendData(dataSendToParent)
    } else {
      dataSendToParent = {
        isBtnDisable: false,
        tableData4: {}
      }
      sendData(dataSendToParent)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, isFormCompleted])

  const handleChange = (e: { target: { id: string; value: string; }; }) => {
    const { id, value } = e.target
    let isPainExp = id.substring(0,2)
    
    if (isPainExp === "PE" && value === "1") {
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "") {
        painSevValue = "0"
      }
      if (workIntValue === "") {
        workIntValue = "0"
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else if (isPainExp === "PE" && value !== "1") {

      // checkPsPeWiValue(bodyPart, value)
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "0") {
        painSevValue = ""
      }
      if (workIntValue === "0") {
        workIntValue = ""
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else {
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
      }))
    }
  }

  const checkPsValue = (bodyPart: string) => {
    let painSevValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          painSevValue = value
          break;      
        default:
          break;
      }
    }
    return painSevValue
  }

  const checkWiValue = (bodyPart: string) => {
    let workIntValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          workIntValue = value
          break;      
        default:
          break;
      }
    }
    return workIntValue
  }

	return (
    <tbody>
      <tr>
        <th scope="row">Area A - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaA" value="1" checked={tableData.PELeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaA" value="2" checked={tableData.PELeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaA" value="3" checked={tableData.PELeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaA" value="4" checked={tableData.PELeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaA" value="5" checked={tableData.PELeftHandAreaA} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaA" value="1" checked={tableData.PSLeftHandAreaA} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaA" value="2" checked={tableData.PSLeftHandAreaA} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaA" value="3" checked={tableData.PSLeftHandAreaA} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaA" value="1" checked={tableData.WILeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaA" value="2" checked={tableData.WILeftHandAreaA} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaA" value="3" checked={tableData.WILeftHandAreaA} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area B - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaB" value="1" checked={tableData.PELeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaB" value="2" checked={tableData.PELeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaB" value="3" checked={tableData.PELeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaB" value="4" checked={tableData.PELeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaB" value="5" checked={tableData.PELeftHandAreaB} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaB" value="1" checked={tableData.PSLeftHandAreaB} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaB" value="2" checked={tableData.PSLeftHandAreaB} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaB" value="3" checked={tableData.PSLeftHandAreaB} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaB" value="1" checked={tableData.WILeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaB" value="2" checked={tableData.WILeftHandAreaB} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaB" value="3" checked={tableData.WILeftHandAreaB} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area C - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaC" value="1" checked={tableData.PELeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaC" value="2" checked={tableData.PELeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaC" value="3" checked={tableData.PELeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaC" value="4" checked={tableData.PELeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaC" value="5" checked={tableData.PELeftHandAreaC} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaC" value="1" checked={tableData.PSLeftHandAreaC} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaC" value="2" checked={tableData.PSLeftHandAreaC} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaC" value="3" checked={tableData.PSLeftHandAreaC} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaC" value="1" checked={tableData.WILeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaC" value="2" checked={tableData.WILeftHandAreaC} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaC" value="3" checked={tableData.WILeftHandAreaC} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area D - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaD" value="1" checked={tableData.PELeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaD" value="2" checked={tableData.PELeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaD" value="3" checked={tableData.PELeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaD" value="4" checked={tableData.PELeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaD" value="5" checked={tableData.PELeftHandAreaD} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaD" value="1" checked={tableData.PSLeftHandAreaD} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaD" value="2" checked={tableData.PSLeftHandAreaD} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaD" value="3" checked={tableData.PSLeftHandAreaD} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaD" value="1" checked={tableData.WILeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaD" value="2" checked={tableData.WILeftHandAreaD} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaD" value="3" checked={tableData.WILeftHandAreaD} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area E - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaE" value="1" checked={tableData.PELeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaE" value="2" checked={tableData.PELeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaE" value="3" checked={tableData.PELeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaE" value="4" checked={tableData.PELeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaE" value="5" checked={tableData.PELeftHandAreaE} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaE" value="1" checked={tableData.PSLeftHandAreaE} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaE" value="2" checked={tableData.PSLeftHandAreaE} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaE" value="3" checked={tableData.PSLeftHandAreaE} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaE" value="1" checked={tableData.WILeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaE" value="2" checked={tableData.WILeftHandAreaE} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaE" value="3" checked={tableData.WILeftHandAreaE} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">Area F - shaded area</th>
        <CMDQTableTDRadio id="PELeftHandAreaF" value="1" checked={tableData.PELeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaF" value="2" checked={tableData.PELeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaF" value="3" checked={tableData.PELeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaF" value="4" checked={tableData.PELeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELeftHandAreaF" value="5" checked={tableData.PELeftHandAreaF} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLeftHandAreaF" value="1" checked={tableData.PSLeftHandAreaF} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaF" value="2" checked={tableData.PSLeftHandAreaF} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLeftHandAreaF" value="3" checked={tableData.PSLeftHandAreaF} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILeftHandAreaF" value="1" checked={tableData.WILeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaF" value="2" checked={tableData.WILeftHandAreaF} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILeftHandAreaF" value="3" checked={tableData.WILeftHandAreaF} onchange={handleChange} classname="background-orange"/>
      </tr>
    </tbody>
	);
};

export default CMDQTableForm4
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCMDQ = /* GraphQL */ `
  mutation CreateCMDQ(
    $input: CreateCMDQInput!
    $condition: ModelCMDQConditionInput
  ) {
    createCMDQ(input: $input, condition: $condition) {
      id
      name
      staffID
      email
      LOB
      PENeck
      PSNeck
      WINeck
      PEShoulderRight
      PSShoulderRight
      WIShoulderRight
      PEShoulderLeft
      PSShoulderLeft
      WIShoulderLeft
      PEUpperBack
      PSUpperBack
      WIUpperBack
      PEUpperArmLeft
      PSUpperArmLeft
      WIUpperArmLeft
      PEUpperArmRight
      PSUpperArmRight
      WIUpperArmRight
      PEForearmLeft
      PSForearmLeft
      WIForearmLeft
      PEForearmRight
      PSForearmRight
      WIForearmRight
      PEWristLeft
      PSWristLeft
      WIWristLeft
      PEWristRight
      PSWristRight
      WIWristRight
      PELowerBack
      PSLowerBack
      WILowerBack
      PEButtockHip
      PSButtockHip
      WIButtockHip
      PEThighLeft
      PSThighLeft
      WIThighLeft
      PEThighRight
      PSThighRight
      WIThighRight
      PEKneeLeft
      PSKneeLeft
      WIKneeLeft
      PEKneeRight
      PSKneeRight
      WIKneeRight
      PELowerLegLeft
      PSLowerLegLeft
      WILowerLegLeft
      PELowerLegRight
      PSLowerLegRight
      WILowerLegRight
      PERightHandAreaA
      PSRightHandAreaA
      WIRightHandAreaA
      PERightHandAreaB
      PSRightHandAreaB
      WIRightHandAreaB
      PERightHandAreaC
      PSRightHandAreaC
      WIRightHandAreaC
      PERightHandAreaD
      PSRightHandAreaD
      WIRightHandAreaD
      PERightHandAreaE
      PSRightHandAreaE
      WIRightHandAreaE
      PERightHandAreaF
      PSRightHandAreaF
      WIRightHandAreaF
      PELeftHandAreaA
      PSLeftHandAreaA
      WILeftHandAreaA
      PELeftHandAreaB
      PSLeftHandAreaB
      WILeftHandAreaB
      PELeftHandAreaC
      PSLeftHandAreaC
      WILeftHandAreaC
      PELeftHandAreaD
      PSLeftHandAreaD
      WILeftHandAreaD
      PELeftHandAreaE
      PSLeftHandAreaE
      WILeftHandAreaE
      PELeftHandAreaF
      PSLeftHandAreaF
      WILeftHandAreaF
      createdAt
      updatedAt
    }
  }
`;
export const updateCMDQ = /* GraphQL */ `
  mutation UpdateCMDQ(
    $input: UpdateCMDQInput!
    $condition: ModelCMDQConditionInput
  ) {
    updateCMDQ(input: $input, condition: $condition) {
      id
      name
      staffID
      email
      LOB
      PENeck
      PSNeck
      WINeck
      PEShoulderRight
      PSShoulderRight
      WIShoulderRight
      PEShoulderLeft
      PSShoulderLeft
      WIShoulderLeft
      PEUpperBack
      PSUpperBack
      WIUpperBack
      PEUpperArmLeft
      PSUpperArmLeft
      WIUpperArmLeft
      PEUpperArmRight
      PSUpperArmRight
      WIUpperArmRight
      PEForearmLeft
      PSForearmLeft
      WIForearmLeft
      PEForearmRight
      PSForearmRight
      WIForearmRight
      PEWristLeft
      PSWristLeft
      WIWristLeft
      PEWristRight
      PSWristRight
      WIWristRight
      PELowerBack
      PSLowerBack
      WILowerBack
      PEButtockHip
      PSButtockHip
      WIButtockHip
      PEThighLeft
      PSThighLeft
      WIThighLeft
      PEThighRight
      PSThighRight
      WIThighRight
      PEKneeLeft
      PSKneeLeft
      WIKneeLeft
      PEKneeRight
      PSKneeRight
      WIKneeRight
      PELowerLegLeft
      PSLowerLegLeft
      WILowerLegLeft
      PELowerLegRight
      PSLowerLegRight
      WILowerLegRight
      PERightHandAreaA
      PSRightHandAreaA
      WIRightHandAreaA
      PERightHandAreaB
      PSRightHandAreaB
      WIRightHandAreaB
      PERightHandAreaC
      PSRightHandAreaC
      WIRightHandAreaC
      PERightHandAreaD
      PSRightHandAreaD
      WIRightHandAreaD
      PERightHandAreaE
      PSRightHandAreaE
      WIRightHandAreaE
      PERightHandAreaF
      PSRightHandAreaF
      WIRightHandAreaF
      PELeftHandAreaA
      PSLeftHandAreaA
      WILeftHandAreaA
      PELeftHandAreaB
      PSLeftHandAreaB
      WILeftHandAreaB
      PELeftHandAreaC
      PSLeftHandAreaC
      WILeftHandAreaC
      PELeftHandAreaD
      PSLeftHandAreaD
      WILeftHandAreaD
      PELeftHandAreaE
      PSLeftHandAreaE
      WILeftHandAreaE
      PELeftHandAreaF
      PSLeftHandAreaF
      WILeftHandAreaF
      createdAt
      updatedAt
    }
  }
`;
export const deleteCMDQ = /* GraphQL */ `
  mutation DeleteCMDQ(
    $input: DeleteCMDQInput!
    $condition: ModelCMDQConditionInput
  ) {
    deleteCMDQ(input: $input, condition: $condition) {
      id
      name
      staffID
      email
      LOB
      PENeck
      PSNeck
      WINeck
      PEShoulderRight
      PSShoulderRight
      WIShoulderRight
      PEShoulderLeft
      PSShoulderLeft
      WIShoulderLeft
      PEUpperBack
      PSUpperBack
      WIUpperBack
      PEUpperArmLeft
      PSUpperArmLeft
      WIUpperArmLeft
      PEUpperArmRight
      PSUpperArmRight
      WIUpperArmRight
      PEForearmLeft
      PSForearmLeft
      WIForearmLeft
      PEForearmRight
      PSForearmRight
      WIForearmRight
      PEWristLeft
      PSWristLeft
      WIWristLeft
      PEWristRight
      PSWristRight
      WIWristRight
      PELowerBack
      PSLowerBack
      WILowerBack
      PEButtockHip
      PSButtockHip
      WIButtockHip
      PEThighLeft
      PSThighLeft
      WIThighLeft
      PEThighRight
      PSThighRight
      WIThighRight
      PEKneeLeft
      PSKneeLeft
      WIKneeLeft
      PEKneeRight
      PSKneeRight
      WIKneeRight
      PELowerLegLeft
      PSLowerLegLeft
      WILowerLegLeft
      PELowerLegRight
      PSLowerLegRight
      WILowerLegRight
      PERightHandAreaA
      PSRightHandAreaA
      WIRightHandAreaA
      PERightHandAreaB
      PSRightHandAreaB
      WIRightHandAreaB
      PERightHandAreaC
      PSRightHandAreaC
      WIRightHandAreaC
      PERightHandAreaD
      PSRightHandAreaD
      WIRightHandAreaD
      PERightHandAreaE
      PSRightHandAreaE
      WIRightHandAreaE
      PERightHandAreaF
      PSRightHandAreaF
      WIRightHandAreaF
      PELeftHandAreaA
      PSLeftHandAreaA
      WILeftHandAreaA
      PELeftHandAreaB
      PSLeftHandAreaB
      WILeftHandAreaB
      PELeftHandAreaC
      PSLeftHandAreaC
      WILeftHandAreaC
      PELeftHandAreaD
      PSLeftHandAreaD
      WILeftHandAreaD
      PELeftHandAreaE
      PSLeftHandAreaE
      WILeftHandAreaE
      PELeftHandAreaF
      PSLeftHandAreaF
      WILeftHandAreaF
      createdAt
      updatedAt
    }
  }
`;

import './CMDQ.css'
import '../../App.css'
import React, { useState, useEffect } from 'react';
import CMDQTableTDRadio from './CMDQTableTDRadio'

export interface Props {
  sendData: any
}

const CMDQTableForm2 = ({ sendData }: Props) => {
  const [isFormCompleted, setIsFormCompleted] = useState(false)
  const [tableData, setTableData] = useState({
    PELowerBack: "",
    PSLowerBack: "",
    WILowerBack: "",
    PEButtockHip: "",
    PSButtockHip: "",
    WIButtockHip: "",
    PEThighLeft: "",
    PSThighLeft: "",
    WIThighLeft: "",
    PEThighRight: "",
    PSThighRight: "",
    WIThighRight: "",
    PEKneeLeft: "",
    PSKneeLeft: "",
    WIKneeLeft: "",
    PEKneeRight: "",
    PSKneeRight: "",
    WIKneeRight: "",
    PELowerLegLeft: "",
    PSLowerLegLeft: "",
    WILowerLegLeft: "",
    PELowerLegRight: "",
    PSLowerLegRight: "",
    WILowerLegRight: ""
  })

  useEffect(() => {
    let checkIsFormCompleted = false
    let dataSendToParent = {}

    for (const value of Object.values(tableData)) {
      if (isNaN(parseInt(value))) {
        checkIsFormCompleted = false;
        break
      }
      checkIsFormCompleted = true;
    }

    checkIsFormCompleted ? setIsFormCompleted(true) : setIsFormCompleted(false)

    if (isFormCompleted) {
      dataSendToParent = {
        isBtnDisable: true,
        tableData2: tableData
      }
      sendData(dataSendToParent)
    } else {
      dataSendToParent = {
        isBtnDisable: false,
        tableData2: {}
      }
      sendData(dataSendToParent)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, isFormCompleted])

  const handleChange = (e: { target: { id: string; value: string; }; }) => {
    const { id, value } = e.target
    let isPainExp = id.substring(0,2)
    
    if (isPainExp === "PE" && value === "1") {
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "") {
        painSevValue = "0"
      }
      if (workIntValue === "") {
        workIntValue = "0"
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else if (isPainExp === "PE" && value !== "1") {

      // checkPsPeWiValue(bodyPart, value)
      let painSevID = "PS" + id.slice(2)
      let painSevValue = checkPsValue(painSevID)
      let workIntID = "WI" + id.slice(2)
      let workIntValue = checkWiValue(workIntID)
      
      if (painSevValue === "0") {
        painSevValue = ""
      }
      if (workIntValue === "0") {
        workIntValue = ""
      }
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
        [painSevID]: painSevValue,
        [workIntID]: workIntValue,
      }))
    } else {
      setTableData(prevState => ({
        ...prevState,
        [id]: value,
      }))
    }
  }

  const checkPsValue = (bodyPart: string) => {
    let painSevValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          painSevValue = value
          break;      
        default:
          break;
      }
    }
    return painSevValue
  }

  const checkWiValue = (bodyPart: string) => {
    let workIntValue = ""

    for (const [key, value] of Object.entries(tableData)) {
      switch (key) {
        case bodyPart:
          workIntValue = value
          break;      
        default:
          break;
      }
    }
    return workIntValue
  }

	return (
    <tbody>
      <tr>
        <th scope="row">11.Lower Back</th>
        <CMDQTableTDRadio id="PELowerBack" value="1" checked={tableData.PELowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerBack" value="2" checked={tableData.PELowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerBack" value="3" checked={tableData.PELowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerBack" value="4" checked={tableData.PELowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerBack" value="5" checked={tableData.PELowerBack} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLowerBack" value="1" checked={tableData.PSLowerBack} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerBack" value="2" checked={tableData.PSLowerBack} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerBack" value="3" checked={tableData.PSLowerBack} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILowerBack" value="1" checked={tableData.WILowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerBack" value="2" checked={tableData.WILowerBack} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerBack" value="3" checked={tableData.WILowerBack} onchange={handleChange} classname="background-orange"/>
      </tr> 
      <tr>
        <th scope="row">12.Buttock/Hip</th>
        <CMDQTableTDRadio id="PEButtockHip" value="1" checked={tableData.PEButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEButtockHip" value="2" checked={tableData.PEButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEButtockHip" value="3" checked={tableData.PEButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEButtockHip" value="4" checked={tableData.PEButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEButtockHip" value="5" checked={tableData.PEButtockHip} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSButtockHip" value="1" checked={tableData.PSButtockHip} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSButtockHip" value="2" checked={tableData.PSButtockHip} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSButtockHip" value="3" checked={tableData.PSButtockHip} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIButtockHip" value="1" checked={tableData.WIButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIButtockHip" value="2" checked={tableData.WIButtockHip} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIButtockHip" value="3" checked={tableData.WIButtockHip} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">13.Left Thigh</th>
        <CMDQTableTDRadio id="PEThighLeft" value="1" checked={tableData.PEThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighLeft" value="2" checked={tableData.PEThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighLeft" value="3" checked={tableData.PEThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighLeft" value="4" checked={tableData.PEThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighLeft" value="5" checked={tableData.PEThighLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSThighLeft" value="1" checked={tableData.PSThighLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSThighLeft" value="2" checked={tableData.PSThighLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSThighLeft" value="3" checked={tableData.PSThighLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIThighLeft" value="1" checked={tableData.WIThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIThighLeft" value="2" checked={tableData.WIThighLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIThighLeft" value="3" checked={tableData.WIThighLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">14.Right Thigh</th>
        <CMDQTableTDRadio id="PEThighRight" value="1" checked={tableData.PEThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighRight" value="2" checked={tableData.PEThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighRight" value="3" checked={tableData.PEThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighRight" value="4" checked={tableData.PEThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEThighRight" value="5" checked={tableData.PEThighRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSThighRight" value="1" checked={tableData.PSThighRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSThighRight" value="2" checked={tableData.PSThighRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSThighRight" value="3" checked={tableData.PSThighRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIThighRight" value="1" checked={tableData.WIThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIThighRight" value="2" checked={tableData.WIThighRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIThighRight" value="3" checked={tableData.WIThighRight} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">15.Left Knee</th>
        <CMDQTableTDRadio id="PEKneeLeft" value="1" checked={tableData.PEKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeLeft" value="2" checked={tableData.PEKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeLeft" value="3" checked={tableData.PEKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeLeft" value="4" checked={tableData.PEKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeLeft" value="5" checked={tableData.PEKneeLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSKneeLeft" value="1" checked={tableData.PSKneeLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSKneeLeft" value="2" checked={tableData.PSKneeLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSKneeLeft" value="3" checked={tableData.PSKneeLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIKneeLeft" value="1" checked={tableData.WIKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIKneeLeft" value="2" checked={tableData.WIKneeLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIKneeLeft" value="3" checked={tableData.WIKneeLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">16.Right Knee</th>
        <CMDQTableTDRadio id="PEKneeRight" value="1" checked={tableData.PEKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeRight" value="2" checked={tableData.PEKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeRight" value="3" checked={tableData.PEKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeRight" value="4" checked={tableData.PEKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PEKneeRight" value="5" checked={tableData.PEKneeRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSKneeRight" value="1" checked={tableData.PSKneeRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSKneeRight" value="2" checked={tableData.PSKneeRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSKneeRight" value="3" checked={tableData.PSKneeRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WIKneeRight" value="1" checked={tableData.WIKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIKneeRight" value="2" checked={tableData.WIKneeRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WIKneeRight" value="3" checked={tableData.WIKneeRight} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">17.Left Lower Leg</th>
        <CMDQTableTDRadio id="PELowerLegLeft" value="1" checked={tableData.PELowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegLeft" value="2" checked={tableData.PELowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegLeft" value="3" checked={tableData.PELowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegLeft" value="4" checked={tableData.PELowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegLeft" value="5" checked={tableData.PELowerLegLeft} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLowerLegLeft" value="1" checked={tableData.PSLowerLegLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerLegLeft" value="2" checked={tableData.PSLowerLegLeft} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerLegLeft" value="3" checked={tableData.PSLowerLegLeft} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILowerLegLeft" value="1" checked={tableData.WILowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerLegLeft" value="2" checked={tableData.WILowerLegLeft} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerLegLeft" value="3" checked={tableData.WILowerLegLeft} onchange={handleChange} classname="background-orange"/>
      </tr>
      <tr>
        <th scope="row">18.Right Lower Leg</th>
        <CMDQTableTDRadio id="PELowerLegRight" value="1" checked={tableData.PELowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegRight" value="2" checked={tableData.PELowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegRight" value="3" checked={tableData.PELowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegRight" value="4" checked={tableData.PELowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="PELowerLegRight" value="5" checked={tableData.PELowerLegRight} onchange={handleChange} classname="background-orange"/>

        <CMDQTableTDRadio id="PSLowerLegRight" value="1" checked={tableData.PSLowerLegRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerLegRight" value="2" checked={tableData.PSLowerLegRight} onchange={handleChange} classname=""/>
        <CMDQTableTDRadio id="PSLowerLegRight" value="3" checked={tableData.PSLowerLegRight} onchange={handleChange} classname=""/>
        
        <CMDQTableTDRadio id="WILowerLegRight" value="1" checked={tableData.WILowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerLegRight" value="2" checked={tableData.WILowerLegRight} onchange={handleChange} classname="background-orange"/>
        <CMDQTableTDRadio id="WILowerLegRight" value="3" checked={tableData.WILowerLegRight} onchange={handleChange} classname="background-orange"/>
      </tr>
    </tbody>
	);
};

export default CMDQTableForm2